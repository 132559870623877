body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Dashboard {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.DashboardHeader, .DashboardFooter {
  background-color: #1c1c1c;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.DashboardSidebar {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  width: 250px;
}

.DashboardContent {
  flex: 1;
  padding: 1rem;
  background-color: #f4f4f4;
}

.Card {
  background-color: #1c1c1c;
  color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

.MuiAppBar-root {
  background-color: #1c1c1c;
}

.MuiDrawer-paper {
  background-color: #333;
  color: #fff;
}

.MuiButton-containedPrimary {
  background-color: #fff;
  color: #000;
}

.MuiTypography-root {
  color: #fff;
}
